.App {
}

.App-header {
  background-color: #eee;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-header ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.App-header li {
  padding: 16px;
}
.App-link {
  color: #61dafb;
}

.Select-role {
  margin-right: 20px;
}

.Link {
  height: 20px;
  color: #ffffff;
  font-family: "Maison Neue", Arial, Arial;
  font-size: 18px;
  font-weight: 300;
  line-height: 18px;
}

.Link:hover {
  color: #999999;
}

.Logo {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Navigation {
  width: 100%;
  height: 45px;
  text-align: right;
  background-color: #003366;
}

.NavigationList {
  height: 100%;
  list-style: none;
  margin: 0px;
}

.LogoWrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}

.NavigationLink {
  display: inline-block;
  margin: 13px 25px 13px 25px;
}

.NavigationLink:hover {
  text-decoration: underline;
  color: #bfc6ca;
}
.NavigationLink > a {
  font-family: "Maison Neue", Arial;
  font-size: 14px;
  color: white;
  text-decoration: none;
}

.Spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #003366;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.InfoBox {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem;
  > div {
    outline: 1px solid #d3d3d3;
    width: clamp(40ch, 30%, 90ch);
    height: fit-content;
    padding: 0.2rem;
    overflow-x: scroll;
  }
}
